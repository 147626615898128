import React, { useCallback, useEffect, useState } from "react";
import Autosuggest from "react-autosuggest";
import { Box } from "theme-ui";

import PropTypes from "prop-types";
import { TextField } from "office-ui-fabric-react";
import { memoize, get } from "../../../utils/lodash";

function shouldRenderSuggestions(/* value, reason */) {
  return true;
}

const renderInputComponent = ({
  showClose,
  clearIt,
  disabled,
  onChange,
  ...inputProps
}) => (
  <TextField
    sx={{
      color: "text",
      ...(showClose && !disabled
        ? { ":disabled": { backgroundColor: "background" } }
        : {}),
    }}
    iconProps={
      showClose &&
      !disabled && {
        iconName: "Cancel",
        style: { pointerEvents: "auto", cursor: "pointer" },
        onClick: clearIt,
      }
    }
    {...inputProps}
    {...{
      onChange: showClose ? clearIt : onChange,
    }}
    disabled={disabled}
  />
);

const useFetchSuggestions = (config, onChange, onBlur, value) => {
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState(config.dflt);
  const [selected, setSelected] = useState(null);

  const resetSelection = useCallback(() => {
    setSelected(null);
    setInputValue("");
    if (value) {
      onChange(undefined, null);
    }
  }, [value]);

  const fetchId = useCallback((propsValue, propsValueDisplay) => {
    if (propsValue) {
      if (propsValueDisplay) {
        setInputValue(propsValueDisplay);
      }
      config.fetch(propsValue).then((result) => {
        setSelected(result);
        setInputValue(config.getSuggestionValue(result));
      });
    } else {
      resetSelection();
    }
  }, []);

  const onChangeS = useCallback((event, { newValue }) => {
    // console.log("N=>", newValue);
    setInputValue(newValue);
  }, []);

  const onBlurS = useCallback(() => {
    setInputValue(selected ? inputValue : "");
    if (onBlur && typeof onBlur === "function") {
      onBlur();
    }
  }, [selected]);

  const getSuggestions = memoize(async (ivalue) => {
    try {
      const itrimValue = ivalue.trim().toLowerCase();
      if (itrimValue) {
        const response = await fetch(
          config.api(itrimValue),
          get(config, "apiParams", () => ({}))()
        );
        const data = await response.json();
        if (data && data.results) {
          if (config.data2results) {
            const ret = config.data2results(
              data,
              itrimValue,
              config.getSuggestionValue,
              config.dflt
            );

            return {
              count: ret.length,
              results: ret,
            };
          }
          if (config.data2resultsAsync) {
            const ret = await config.data2resultsAsync(
              data,
              itrimValue,
              config.getSuggestionValue,
              config.dflt
            );
            return {
              count: ret.length,
              results: ret,
            };
          }
        }
      }
    } catch (e) {
      return { count: 0, results: config.dflt };
    }

    return { count: 0, results: config.dflt };
  });

  const onSuggestionsClearRequested = useCallback(() => {
    setSuggestions(config.dflt);
  }, []);

  const onSuggestionsFetchRequested = useCallback(({ value: itrimValue }) => {
    getSuggestions(itrimValue).then((data) => {
      if (data.Error) {
        onSuggestionsClearRequested();
      } else {
        setSuggestions(data.results);
      }
    });
  }, []);

  const onSuggestionSelected = useCallback(
    (event, { suggestion }) => {
      if (
        onChange &&
        typeof onChange === "function" &&
        suggestion &&
        suggestion.id
      ) {
        onChange(suggestion.id, suggestion);
      }
      setSelected(suggestion);
    },
    [onChange]
  );

  // const selectedId = selected ? selected.id : undefined;

  // useEffect(() => {
  //   if (onChange && typeof onChange === "function" && value !== selectedId) {
  //     console.log("onchange called", selectedId);
  //     onChange(selectedId, selected);
  //   }
  // }, [selectedId, value]);

  return {
    inputValue,
    selected,
    suggestions,
    fetchId,
    onChangeS,
    onBlurS,
    getSuggestions,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
    onSuggestionSelected,
    resetSelection,
  };
};

function ServerAutoSuggest({
  config,
  value,
  onChange,
  onBlur,
  valueDisplay,
  renderPrint,
  ...restprops
}) {
  const {
    inputValue,
    // selected,
    suggestions,
    fetchId,
    onChangeS,
    onBlurS,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
    onSuggestionSelected,
    resetSelection,
  } = useFetchSuggestions(config, onChange, onBlur, value);

  useEffect(() => {
    fetchId(value, valueDisplay);
  }, [value]);

  if (renderPrint) {
    return renderPrint(inputValue);
  }

  // Adding AutoSuggest component
  return (
    <Box variant="boxes.autosuggest">
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={config.getSuggestionValue}
        renderSuggestion={config.renderSuggestion}
        alwaysRenderSuggestions={false}
        shouldRenderSuggestions={shouldRenderSuggestions}
        onSuggestionSelected={onSuggestionSelected}
        focusInputOnSuggestionClick={false}
        inputProps={{
          placeholder: "Type...",
          ...restprops,
          value: inputValue,
          onChange: onChangeS,
          onBlur: onBlurS,
          clearIt: resetSelection,
          showClose: !!value,
        }}
        renderInputComponent={renderInputComponent}
      />
    </Box>
  );
}

ServerAutoSuggest.propTypes = {
  config: PropTypes.shape({
    getSuggestionValue: PropTypes.func,
    renderSuggestion: PropTypes.func,
  }).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueDisplay: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  renderPrint: PropTypes.func,
};

ServerAutoSuggest.defaultProps = {
  value: "",
  valueDisplay: null,
  onChange: null,
  onBlur: null,
  renderPrint: null,
};

export default React.memo(ServerAutoSuggest);
