import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import useMyArray from "../../../hooks/useMyArray";
import { profileApiCachedUrl } from "../../../service-wrappers/profile-api";
import ServerAutoSuggest from "../ServerAutoSuggest";
import Select from "../Select";

export const fetchGroup = (id) => {
  return fetch(profileApiCachedUrl("groups-id", id)).then((R) => R.json());
};

const fetchChildren = (parentId) => {
  return fetch(profileApiCachedUrl("groups-parent-id", parentId)).then((R) =>
    R.json()
  );
};

const dflt = [];

function getConfigs({ includeOnly }) {
  const config = {
    dflt: includeOnly ? dflt.filter(includeOnly) : dflt,

    fetch: fetchGroup,

    // Trigger suggestions
    getSuggestionValue: (suggestion) => suggestion.name,
    // Render Each Option
    renderSuggestion: (suggestion) => suggestion.name,
    api: (itrimValue) =>
      profileApiCachedUrl("groups-center-prefix", itrimValue.substring(0, 1)),
    data2results: (data, itrimValue, getSuggestionValue, dflt1) => {
      const dfltIds = dflt1.map((r) => r.id);
      const filterFn = (f, itrimValue1) =>
        getSuggestionValue(f).toLowerCase().startsWith(itrimValue1);
      return [
        ...dflt1.filter((f) => filterFn(f, itrimValue)),
        ...data.results
          .filter(
            (f) =>
              (!includeOnly || includeOnly(f)) &&
              f.active &&
              filterFn(f, itrimValue) &&
              !dfltIds.includes(f.id)
          )
          .filter((i, idx) => idx < 20),
      ];
    },
  };

  return config;
}

const GroupChildrenSelection = ({
  name,
  parentid,
  options,
  value,
  valueDisplay,
  ...restprops
}) => {
  const [myOptions, { merge, set, filter }] = useMyArray(options);

  useEffect(() => {
    if (parentid) {
      const fetchAndStore = (p) => {
        fetchChildren(p)
          .then((data) => {
            if (data.count) {
              set(
                data.results.map((r) => ({ name: r.id, label: r.name })),
                (r) => r.name
              );
            }
          })
          .catch(() => {
            // console.error(p, e)
          });
        if (value) {
          fetchChildren(value)
            .then((data) => {
              if (data.count) {
                merge(
                  data.results.map((r) => ({ name: r.id, label: r.name })),
                  (r) => r.name
                );
                filter((r) => r.name === value);
              }
            })
            .catch(() => {
              // console.error(p, e)
            });
        }
      };
      fetchAndStore(parentid);
    }
  }, [parentid, merge, set, filter, value]);

  useEffect(() => {
    if (value) {
      if (!myOptions.find((r) => r.name === value)) {
        set([{ name: value, label: valueDisplay }, ...myOptions]);
      }
    }
  }, [value, valueDisplay, set, myOptions]);

  // console.log(options, myOptions);

  return (
    <Select name={name} {...restprops} value={value} options={myOptions} />
  );
};

GroupChildrenSelection.propTypes = {
  name: PropTypes.string.isRequired,
  parentid: PropTypes.number.isRequired,
  options: PropTypes.shape([]),
  value: PropTypes.number,
  valueDisplay: PropTypes.string,
};
GroupChildrenSelection.defaultProps = {
  options: [],
  value: undefined,
  valueDisplay: undefined,
};

const Group = ({ name, parent, siblingsOf, includeOnly, ...restprops }) => {
  const [parentId, setParentId] = useState(parent);

  useEffect(() => {
    if (siblingsOf) {
      fetchGroup(siblingsOf).then((data) => {
        setParentId(data.parent);
      });
    }
  }, [siblingsOf]);
  const config = useMemo(() => getConfigs({ includeOnly }), [includeOnly]);

  if (parent || siblingsOf) {
    return (
      <GroupChildrenSelection name={name} parentid={parentId} {...restprops} />
    );
  }
  return <ServerAutoSuggest {...restprops} config={config} />;
};

Group.propTypes = {
  name: PropTypes.string.isRequired,
  parent: PropTypes.number,
  siblingsOf: PropTypes.number,
  includeOnly: PropTypes.func,
};

Group.defaultProps = {
  siblingsOf: null,
  parent: null,
  includeOnly: null,
};

export default Group;
